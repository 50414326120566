@import '../../styles/customMediaQueries.css';
.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}
.locationContainer {
  display: flex;
  justify-content: space-between;
  background-color: var(--colorWhite);
  padding: 0.5rem 0.5rem;
  @media (--viewportMedium) {
    padding: 0;
    padding-left: 0.5rem;
  }
  border-radius: 30px;
}
.keywordField {
  /* width: 40%; */
  width: auto;
  @media (--viewportMedium) {
    width: 45%;
  }

  padding: 0.5rem;
  border-right: 1px solid var(--colorGrey200);
}
.locationField {
  /* width: 40%; */
  padding: 0.5rem;
}
.searchButton {
  /* width: 15%; */
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
  width: 100px;
  border-radius: 0px 30px 30px 0px;
  background-color: var(--colorBlack);
  &:hover {
    background-color: var(--colorBlack);
  }
}

.locationAutocompleteInput {
  border: unset;
  &:hover {
    border: unset;
  }
  &:focus {
    border: unset;
    box-shadow: unset;
  }
  &:active {
    border: unset;
    box-shadow: unset;
  }
}
.keywordInputRoot {
  border: unset;
  &:hover {
    border: unset;
  }
  &:focus {
    border: unset;
    box-shadow: unset;
  }
  &:active {
    border: unset;
    box-shadow: unset;
  }
}
.locationAddress {
  width: auto;
  padding: 0.5rem;
  @media (--viewportMedium) {
    width: 45%;
  }
}
.borderLine {
  margin: 0;
  padding: 0;
}
.desktopicon {
  display: none;
  @media (--viewportMedium) {
    display: block;
    font-size: 30px;
    margin-top: 18px;
    margin-left: 0.5rem;
  }
}
.mobileIcon {
  display: block;
  font-size: 25px;
  margin-top: 13px;
  margin-right: 0.5rem;
  cursor: pointer;
  @media (--viewportMedium) {
    display: none;
  }
}
